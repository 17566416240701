<template>
  <v-container fluid class="px-6 py-6">
    <v-row>
      <v-col cols="12">
        <v-card>
          <div class="px-4 py-4 mt-2">
            <v-card class="border-radius-xl">
              <v-row>
                <v-col lg="6">
                  <div class="mb-10">
                    <h5 class="font-weight-bold text-h5 text-typo mb-0">
                      Xөдөлгөөн xяаналт
                    </h5>
                    <p class="text-sm text-body mb-0">
                      Бодит цагт, автобусны ирцийг xянаx, удирдана.
                    </p>
                  </div>
                </v-col>
                <v-col lg="6" class="text-end my-auto"> </v-col>
              </v-row>
              <v-card-text v-if="buses">
                <v-row class="mx-10" justify="start">
                  <v-col md="2" lg="2" sm="12">
                    <v-card
                      v-for="(bus, busIndex) in buses"
                      :key="'bss' + busIndex"
                      class="card-shadow card-padding border-radius-xl mx-1"
                      min-height="400"
                    >
                      <v-card-text>
                        <!-- <v-data-table>
                          :item
                        </v-data-table> -->
                        <h1>{{ bus.name }}</h1>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// const fb = require("@/firebaseConfig.js");
import { sync } from "vuex-pathify";
export default {
  components: {},
  computed: {
    ...sync("*"),
  },
  data() {
    return {
      collectionName: "_busRegistration",
      viewFull: true,
      buses: null,
    };
  },
  methods: {
    _getParent() {
      return this.userData.ref.parent.parent;
    },
  },

  created() {
    this._getParent()
      .collection(this.collectionName)
      .where("deleted", "==", false)
      .orderBy("createdAt", "asc")
      .onSnapshot((docs) => {
        this.buses = [];
        var counter = 0;
        docs.forEach((doc) => {
          let bus = doc.data();
          bus.ref = doc.ref;
          bus.id = doc.id;
          bus.index = counter++;
          bus.members = null;
          bus.ref.collection("bus-students").onSnapshot((docs) => {
            bus.members = [];
            docs.forEach((doc) => {
              let student = doc.data();
              student.id = doc.id;
              student.ref = doc.ref;
              bus.members.push(student);
            });
          });
          this.buses.push(bus);
        });
      });
  },
};
</script>
<style>
</style>